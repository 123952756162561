import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import useUserPlan from 'hooks/useUserPlan'
import { useForm } from 'react-hook-form'
import ReactPlayer from 'react-player'

import Button from 'components/Button'
import ButtonLink from 'components/ButtonLink'
import ImageDropzone from 'components/ImageDropzone'
import Input from 'components/Input'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import Tabs from 'components/Tabs'
import getCdnUrl from 'utils/getCdnUrl'

const SlideMediaUploadModal = ({
  media,
  show,
  onClose,
  onChange,
  unsplash,
  youtube,
}) => {
  const userPlan = useUserPlan()
  let defaultTab = 'upload'
  if (media?.source === 'youtube') {
    defaultTab = 'youtube'
  }
  if (media?.source === 'unsplash') {
    defaultTab = 'unsplash'
  }
  const [tab, setTab] = useState(defaultTab)
  const [tempMedia, setTempMedia] = useState(media || {})
  const [isLoading, setIsLoading] = useState(false)
  const [images, setImages] = useState([])
  const { register, handleSubmit } = useForm()
  const [params, setParams] = useState({ page: 1, query: '' })
  const [previewImage, setPreviewImage] = useState(null)

  const hasLibrary = userPlan?.plan?.features?.imagesLibrary

  const getImages = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get('/api/photos/search', {
        params,
      })
      setImages(data.results)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getImages()
  }, [])

  useEffect(() => {
    getImages()
  }, [params])

  const onSubmit = async ({ query }) => {
    setParams({ page: 1, query })
  }

  const handleNextPage = async () => {
    setParams((val) => ({ ...val, page: val.page + 1 }))
  }

  const handleImageUpload = (image) => {
    const { url, height, width } = image
    onChange({
      url,
      height,
      width,
      type: 'image',
      source: 'file',
    })
  }

  const handleAddImage = (image) => {
    onChange({
      url: image.urls.full,
      type: 'image',
      source: 'unsplash',
      author: {
        name: image.user.name,
        link: image.user.links.html,
      },
    })
  }

  const handlePreviewImageAdd = () => {
    onChange({
      url: previewImage.urls.full,
      type: 'image',
      source: 'unsplash',
      author: {
        name: previewImage.user.name,
        link: previewImage.user.links.html,
      },
    })
    setPreviewImage(null)
  }

  const handleVideoAdd = () => {
    onChange({
      ...tempMedia,
      type: 'video',
      source: 'youtube',
    })
  }

  const tabs = useMemo(() => {
    const tabsList = [{ key: 'upload', title: 'Загрузить файл' }]
    if (unsplash) {
      tabsList.push({ key: 'unsplash', title: 'Библиотека изображений' })
    }

    if (youtube) {
      tabsList.push({ key: 'youtube', title: 'Ссылка на YouTube' })
    }
    return tabsList
  }, [unsplash, youtube])

  return (
    <>
      <Modal
        title="Добавить медиа"
        show={show}
        onClose={onClose}
        panelClassName="max-w-3xl"
      >
        {tabs.length > 1 && (
          <Tabs
            tab={tab}
            onChange={(nextTab) => {
              setTab(nextTab)
              setTempMedia({
                ...tempMedia,
                url: '',
              })
            }}
            tabs={tabs}
          />
        )}
        <div className="pt-4">
          {tab === 'upload' && (
            <div className="aspect-h-9 aspect-w-16 relative">
              <div className="absolute h-full w-full">
                <ImageDropzone onChange={handleImageUpload} withSizes />
              </div>
            </div>
          )}
          {tab === 'unsplash' && (
            <>
              {hasLibrary && (
                <div className="aspect-h-9 aspect-w-16 relative">
                  <div className="absolute flex h-full w-full flex-col">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="mb-4 flex items-center"
                    >
                      <Input
                        {...register('query')}
                        placeholder="Поиск изображений на Unsplash"
                        autoComplete="off"
                      />
                      <Button
                        className="ml-2"
                        disabled={isLoading}
                        type="submit"
                      >
                        Найти
                        {isLoading && <Loader size={5} className="ml-2" />}
                      </Button>
                    </form>
                    {images.length > 0 && (
                      <div className="h-full overflow-y-scroll">
                        <div className="grid grid-cols-3 gap-2">
                          {images.map((image) => (
                            <div className="group relative flex h-auto items-center border bg-slate-200 p-1">
                              <img
                                src={getCdnUrl(image.urls.small)}
                                alt={image.alt_description}
                              />
                              <div className="absolute bottom-2 right-2 flex opacity-0 transition group-hover:opacity-100">
                                <Button
                                  size="sm"
                                  onClick={() => setPreviewImage(image)}
                                  icon="MagnifyingGlassIcon"
                                  className="mr-1"
                                />
                                <Button
                                  size="sm"
                                  onClick={() => handleAddImage(image)}
                                  icon="CheckIcon"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="mt-2 flex justify-end">
                          {params.page > 1 && (
                            <Button
                              onClick={handleNextPage}
                              size="sm"
                              icon="ArrowLeftIcon"
                              className="mr-2"
                            />
                          )}
                          <Button
                            onClick={handleNextPage}
                            size="sm"
                            icon="ArrowRightIcon"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!hasLibrary && (
                <div className="flex flex-col items-center">
                  <p className="mb-4">
                    Библиотека изображений доступна на тарифах «Стандартный» и
                    «Премиум». Обновите тарифный план, чтобы эта опция стала вам
                    доступна.
                  </p>
                  <ButtonLink
                    href="/pricing"
                    variant="success"
                    icon="StarIcon"
                    onClick={() => {
                      global.dataLayer.push({
                        event: 'logEvent',
                        eventType: 'Update Pricing Plan Clicked',
                        eventProperties: {
                          source: 'Images Library',
                        },
                      })
                    }}
                  >
                    Оформить подписку
                  </ButtonLink>
                </div>
              )}
            </>
          )}
          {tab === 'youtube' && (
            <div>
              <div className="mb-4">
                <Input
                  placeholder="Ссылка на YouTube"
                  autoComplete="off"
                  onChange={(e) => setTempMedia({ url: e.target.value })}
                  value={tempMedia?.url || ''}
                />
              </div>
              <div className="aspect-h-9 aspect-w-16 relative">
                <div className="absolute inset-0 h-full w-full">
                  {tempMedia?.url && (
                    <ReactPlayer
                      url={tempMedia.url}
                      controls
                      width="100%"
                      height="100%"
                    />
                  )}
                </div>
              </div>
              {tempMedia?.url && (
                <div className="mt-4">
                  <div className="mb-4">
                    <label>
                      <input
                        className="mr-2"
                        type="checkbox"
                        checked={tempMedia?.autoplay}
                        onChange={(event) =>
                          setTempMedia({
                            ...tempMedia,
                            autoplay: event.target.checked,
                          })
                        }
                      />
                      <span>Автоматически включать видео</span>
                    </label>
                  </div>
                  <Button variant="success" onClick={handleVideoAdd}>
                    Сохранить
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
      <Modal
        show={!!previewImage}
        onClose={() => setPreviewImage(null)}
        panelClassName="max-w-5xl"
      >
        {previewImage && (
          <>
            <div className="aspect-h-9 aspect-w-16 relative mb-4">
              <div className="absolute inset-0 flex h-full w-full justify-center">
                <img
                  src={getCdnUrl(previewImage.urls.full)}
                  alt="preview"
                  className="max-h-full max-w-full"
                />
              </div>
            </div>
            <div>
              <Button variant="success" onClick={handlePreviewImageAdd}>
                Сохранить
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

export default SlideMediaUploadModal
