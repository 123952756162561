import React, { useCallback, useState } from 'react'
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  PencilIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import classnames from 'classnames'
import { useTranslation } from 'next-i18next'
import { useDropzone } from 'react-dropzone'
import ReactPlayer from 'react-player'
import { useStorage } from 'reactfire'

import Loader from 'components/Loader'
import SlideMedia from 'components/SlideMedia'
import SlideMediaUploadModal from 'components/SlideMediaUploadModal'
import Wrapper16x9 from 'components/Wrapper16x9'

import 'firebase/storage'

export default function SlideMediaUpload({ slide, slideRef }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isImageUploadOpen, setIsImageUploadOpen] = useState(false)
  const storage = useStorage()

  const onDrop = useCallback(async (files) => {
    const fileToUpload = files[0]
    const timestamp = Number(new Date())
    const fileName = `${timestamp}-${fileToUpload?.name || ''}`
    const newRef = storage.ref('images').child(fileName)
    const metadata = {
      cacheControl: 'public, max-age=31536000',
    }
    const uploadTask = newRef.put(fileToUpload, metadata)

    setIsLoading(true)

    try {
      const res = await uploadTask
      const url = await res.ref.getDownloadURL()
      slideRef.update({
        media: {
          type: fileToUpload.type.split('/')[0] || 'image',
          url,
          source: 'file',
        },
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const className = classnames(
    'flex items-center justify-center w-full rounded-md overflow-hidden h-full border border-dashed transition duration-200 font-default relative',
    isDragActive ? 'border-blue-600' : 'border-gray-300',
    slide?.media ? '' : 'cursor-pointer',
  )

  const handleDelete = (event) => {
    event.stopPropagation()
    slideRef.update({ media: null })
    slideRef?.update({ media: null })
  }

  const handleMediaChange = (media) => {
    slideRef.update({ media })
    setIsImageUploadOpen(false)
  }

  const handleLibraryClose = () => {
    setIsImageUploadOpen(false)
  }

  const handleEdit = (event) => {
    event.stopPropagation()
    setIsImageUploadOpen(true)
  }

  const handlePlacement = (event) => {
    event.stopPropagation()

    const media = slide?.media
    const mediaPlacement = media?.placement === 'cover' ? 'contain' : 'cover'
    slideRef.update({ media: { ...media, placement: mediaPlacement } })
  }

  const buttons = (
    <div className="absolute right-2 top-2 flex flex-col">
      <button
        type="button"
        onClick={handleDelete}
        className="mb-2 rounded-full border border-gray-500 bg-white p-2 text-gray-500 hover:text-gray-700"
      >
        <XMarkIcon className="h-4 w-4" />
      </button>
      <button
        type="button"
        onClick={handleEdit}
        className="mb-2 rounded-full border border-gray-500 bg-white p-2 text-gray-500 hover:text-gray-700"
      >
        <PencilIcon className="h-4 w-4" />
      </button>
      <button
        type="button"
        onClick={handlePlacement}
        className="rounded-full border border-gray-500 bg-white p-2 text-gray-500 hover:text-gray-700"
      >
        {slide?.media?.placement === 'cover' ? (
          <ArrowsPointingInIcon className="h-4 w-4" />
        ) : (
          <ArrowsPointingOutIcon className="h-4 w-4" />
        )}
      </button>
    </div>
  )

  if (slide?.media?.type === 'video') {
    const url = new URL(slide?.media?.url)
    url.searchParams.set('rel', '0')

    return (
      <div className="relative h-full w-full">
        <div className="absolute left-0 top-0 h-full w-full">
          <Wrapper16x9>
            <ReactPlayer controls url={url.href} height="100%" width="100%" />
          </Wrapper16x9>
        </div>
        {buttons}
        <SlideMediaUploadModal
          media={slide?.media}
          show={isImageUploadOpen}
          onClose={handleLibraryClose}
          onChange={handleMediaChange}
          unsplash
          youtube
        />
      </div>
    )
  }

  // Check
  const hasMedia = slide?.media || slide?.image

  return (
    <>
      <div
        className={className}
        {...getRootProps()}
        onClick={() => setIsImageUploadOpen(true)}
        onKeyPress={() => setIsImageUploadOpen(true)}
        role="button"
        tabIndex="0"
      >
        <input {...getInputProps()} />
        {isLoading ? (
          <>
            <div className="text-blue-600">
              <Loader />
            </div>
          </>
        ) : (
          <>
            {hasMedia && (
              <>
                <SlideMedia slide={slide} />
                {buttons}
              </>
            )}
            {!hasMedia && (
              <div>
                {isDragActive ? (
                  <p className="text-main text-center text-sm">
                    {t('mediaUpload.select')}
                  </p>
                ) : (
                  <>
                    <p className="text-main text-center text-sm">
                      {t('mediaUpload.drag')}
                    </p>
                  </>
                )}
              </div>
            )}
          </>
        )}
        <SlideMediaUploadModal
          media={slide?.media}
          show={isImageUploadOpen}
          onClose={handleLibraryClose}
          onChange={handleMediaChange}
          unsplash
          youtube
        />
      </div>
    </>
  )
}
