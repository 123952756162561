import React from 'react'

function SlideLayout({ slide, media, options, text }) {
  const layout = slide?.layoutType || 'no-media'

  switch (layout) {
    case 'media-only':
      return (
        <div className="flex h-full w-full flex-col" key={layout}>
          <div className="flex h-full w-full flex-1 items-start">
            <div className="h-full w-full flex-1">{media}</div>
          </div>
          {options && <div className="mt-4">{options}</div>}
        </div>
      )
    case 'media-left':
      return (
        <div className="flex h-full w-full flex-col" key={layout}>
          <div className="flex h-full w-full flex-col items-start md:flex-row">
            <div className="mb-4 h-full w-full md:mb-0 md:mr-4">{media}</div>
            <div className="flex h-full w-full flex-col">
              <div className="flex-1">{text}</div>
            </div>
          </div>
          {options && <div className="mt-4">{options}</div>}
        </div>
      )
    case 'media-right':
      return (
        <div className="flex h-full w-full flex-col" key={layout}>
          <div className="flex h-full w-full flex-col items-start md:flex-row">
            <div className="mb-4 flex h-full w-full flex-col md:mb-0 md:mr-4">
              <div className="flex-1">{text}</div>
            </div>
            <div className="h-full w-full">{media}</div>
          </div>
          {options && <div className="mt-4">{options}</div>}
        </div>
      )
    case 'media-top':
      return (
        <div className="flex h-full w-full flex-col" key={layout}>
          <div className="mb-4 flex h-full w-full items-start">
            <div className="mb-4 h-full w-full">{media}</div>
          </div>
          <div className="h-1/3 w-full">{text}</div>
          {options && <div className="mt-4">{options}</div>}
        </div>
      )
    case 'media-bottom':
      return (
        <div className="flex h-full w-full flex-col" key={layout}>
          <div className="mb-4 flex h-1/3 w-full items-start">
            <div className="mb-4 h-full w-full">{text}</div>
          </div>
          <div className="h-full w-full">{media}</div>
          {options && <div className="mt-4">{options}</div>}
        </div>
      )
    default:
      return (
        <div className="flex h-full w-full flex-col" key={layout}>
          <div className="flex h-full w-full flex-1 items-start">
            <div className="flex h-full w-full flex-1">{text}</div>
          </div>
          {options && <div className="mt-4">{options}</div>}
        </div>
      )
  }
}

export default SlideLayout
