import React from 'react'
import Slider from 'rc-slider'

import 'rc-slider/assets/index.css'

const NumberSlider = ({
  value,
  onChange,
  min,
  max,
  step,
  answer,
  disabled,
  theme,
  input,
}) => {
  const handleChange = (val) => {
    onChange?.(val)
  }

  const hasValue = value !== undefined && value !== null
  const hasAnswer = answer !== undefined && answer !== null

  const colorPrimary = theme?.colors?.primary || '#1E5EEB'
  const colorSuccess = theme?.colors?.success || '#069668'

  const color = hasValue || !hasAnswer ? colorPrimary : colorSuccess

  const valuePercent = hasValue
    ? Math.round(((value - min) * 100) / (max - min))
    : 0

  const marks =
    input && hasAnswer
      ? {
          [answer]: {
            label: answer,
            style: {
              color: 'white',
              backgroundColor: colorSuccess,
              fontSize: '1.25rem',
              padding: '0.25rem',
              borderRadius: '0.25rem',
              fontWeight: 500,
            },
          },
        }
      : null

  return (
    <div className="flex flex-col items-center">
      <div className="relative h-8 w-full">
        <div
          className={`absolute -translate-x-1/2 text-lg lg:text-xl ${
            hasValue ? 'visible' : 'invisible'
          }`}
          style={{ left: `${valuePercent}%` }}
        >
          {value}
        </div>
      </div>
      <Slider
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        value={hasValue ? value : answer}
        marks={marks}
        onChange={handleChange}
        styles={{
          handle: {
            borderColor: color,
            backgroundColor: color,
            width: 32,
            height: 32,
            marginTop: -12,
            opacity: 1,
            boxShadow: 'none',
          },
          track: {
            backgroundColor: color,
            height: 10,
          },
          rail: {
            height: 10,
          },
        }}
      />
      <div className="mb-4 flex w-full justify-between">
        <span className="text-sm opacity-50 lg:text-xl">{min}</span>
        <span className="text-sm opacity-50 lg:text-xl">{max}</span>
      </div>
    </div>
  )
}

export default NumberSlider
