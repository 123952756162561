import React from 'react'
import ReactPlayer from 'react-player'

import Wrapper16x9 from 'components/Wrapper16x9'
import getCdnUrl from 'utils/getCdnUrl'

const SlideMedia = ({ slide }) => {
  const imageUrl = slide?.media?.url || slide?.image?.urls?.full || slide?.image
  const autoplay = slide?.media?.autoplay || false

  if (slide?.media?.type === 'video') {
    const url = new URL(slide?.media?.url)
    url.searchParams.set('rel', '0')

    return (
      <div className="relative h-full w-full">
        <div className="absolute left-0 top-0 h-full w-full">
          <Wrapper16x9>
            <ReactPlayer
              url={url.href}
              controls
              height="100%"
              width="100%"
              playing={autoplay}
              config={{ youtube: { playerVars: { rel: 0 } } }}
            />
          </Wrapper16x9>
        </div>
      </div>
    )
  }

  const isUnsplash = slide?.media?.source === 'unsplash'

  return (
    <div className="relative h-full w-full">
      <div className="absolute left-0 top-0 flex h-full w-full flex-col">
        <img
          src={getCdnUrl(imageUrl)}
          alt=""
          className={`max-h-full max-w-full flex-1 ${
            slide?.media?.placement === 'cover'
              ? 'object-cover'
              : 'object-contain'
          } ${isUnsplash ? 'pb-4' : ''}`}
        />
        {isUnsplash && (
          <p className="text-main -mt-3 text-center text-xs opacity-50">
            Фото от{' '}
            <a
              className="underline"
              href={`https://unsplash.com/@${slide?.media?.author?.username}?utm_source=unislide&utm_medium=referral`}
              target="_blank"
              rel="noreferrer"
            >
              {slide?.media?.author?.name}
            </a>{' '}
            на{' '}
            <a
              className="underline"
              href="https://unsplash.com/?utm_source=unislide&utm_medium=referral"
              target="_blank"
              rel="noreferrer"
            >
              Unsplash
            </a>
          </p>
        )}
      </div>
    </div>
  )
}

export default SlideMedia
