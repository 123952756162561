import { useState } from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

const Timer = ({ duration, onComplete, theme, ...props }) => {
  const [completed, setCompleted] = useState(false)
  const realDuration = parseInt(duration)
  const width = useWindowWidth()

  const className = completed ? 'animate__animated animate__shakeX' : ''

  const handleComplete = () => {
    setCompleted(true)
    if (typeof onComplete === 'function') {
      onComplete()
    }
  }

  const colors = theme?.colors?.main || [
    ['#004777', 0.33],
    ['#F7B801', 0.33],
    ['#A30000', 0.33],
  ]

  return (
    <div className={className}>
      <CountdownCircleTimer
        isPlaying
        duration={realDuration}
        colors={colors}
        trailColor="transparent"
        size={width > 780 ? 56 : 32}
        strokeWidth={width > 780 ? 4 : 2}
        onComplete={handleComplete}
        {...props}
      >
        {({ remainingTime }) => (
          <span className="text-sm font-medium md:text-2xl ">
            {remainingTime}
          </span>
        )}
      </CountdownCircleTimer>
    </div>
  )
}

export default Timer
