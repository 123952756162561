import React, { useEffect, useMemo } from 'react'
import Color from 'color'

import getCdnUrl from 'utils/getCdnUrl'

const ThemeStyle = ({ theme: themeProp = {} }) => {
  const theme = useMemo(
    () => ({
      ...themeProp,
      fonts: themeProp.fonts || {
        main: {
          family:
            themeProp.font?.family && themeProp.font?.family !== 'CustomFont'
              ? themeProp.font?.family
              : 'Rubik',
          url: themeProp.font?.url,
          source: themeProp.font?.url ? 'file' : 'google',
        },
        heading: {
          family:
            themeProp.font?.family && themeProp.font?.family !== 'CustomFont'
              ? themeProp.font?.family
              : 'Rubik',
          url: themeProp.font?.url,
          source: themeProp.font?.url ? 'file' : 'google',
        },
      },
    }),
    [themeProp],
  )
  const colors = theme?.colors
  const images = theme?.images
  if (!colors) {
    return null
  }

  const bg = Color(colors.background)
  const main = Color(colors.main)

  const defaultColor = main.mix(Color(bg), 0.9)

  const fade = bg.isDark()
    ? Color('white').alpha(0.1)
    : Color('black').alpha(0.05)

  colors.default = defaultColor.toString()
  colors.fade = fade.toString()

  useEffect(() => {
    import('webfontloader').then((WebFont) => {
      if (
        theme?.fonts?.heading?.source === 'google' ||
        !theme?.fonts?.heading?.url
      ) {
        WebFont.load({
          google: {
            families: [`${theme?.fonts?.heading?.family}:400,400i,700,700i`],
          },
        })
      }
      if (theme?.fonts?.main?.source === 'google' || !theme?.fonts?.main?.url) {
        WebFont.load({
          google: {
            families: [`${theme?.fonts?.main?.family}:400,400i,700,700i`],
          },
        })
      }
    })
  }, [theme])

  let css = Object.entries(colors)
    .map(
      ([key, value]) => `
    .custom-theme-${theme?.id} .text-${key} {
      color: ${value};
    }
    .custom-theme-${theme?.id} .bg-${key} {
      background-color: ${value};
    }
    .custom-theme-${theme?.id} .border-${key} {
      border-color: ${value};
    }
  `,
    )
    .join('')
  if (images?.background) {
    css += `
    .custom-theme-${theme?.id} .bg-image {
      position: relative;
    }
    .custom-theme-${theme?.id} .bg-image:before {
      background-image: url("${getCdnUrl(images?.background?.url)}");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      content: ' ';
      height: 100%;
      position: absolute;
      opacity: ${images?.background?.opacity};
      width: 100%;
    }`
  }

  css += `
    @font-face {
      font-family: 'CustomMainFont';
      src: url('${theme?.fonts?.main?.url}');
    }
    @font-face {
      font-family: 'CustomHeadingFont';
      src: url('${theme?.fonts?.heading?.url}');
    }
   `

  css += `
    .custom-theme-${theme?.id} {
      --color-main: ${colors.main};
      --color-heading: ${colors.heading || colors.main};
      --font-family-main: "${
        theme?.fonts?.main?.source === 'file' && theme?.fonts?.main?.url
          ? 'CustomMainFont'
          : theme?.fonts?.main?.family
      }";
      --font-family-heading: "${
        theme?.fonts?.heading?.source === 'file' && theme?.fonts?.heading?.url
          ? 'CustomHeadingFont'
          : theme?.fonts?.heading?.family
      }";
  }
  `

  css += `
    .custom-theme-${theme?.id} {
      --color-success: ${colors.success};
      color: var(--color-main); 
      font-family: var(--font-family-main);
    }
  `

  // Apply dark mode
  useEffect(() => {
    if (bg.isDark()) {
      document.body.classList.add('dark')
    }
  }, [bg])

  return (
    <>
      <style>{css}</style>
    </>
  )
}

export default ThemeStyle
