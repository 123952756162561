import React, { useEffect, useRef, useState } from 'react'

const Wrapper16x9 = ({ children }) => {
  const containerRef = useRef(null)

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return
      }

      const entry = entries[0]
      const containerWidth = entry.contentRect.width
      const containerHeight = (containerWidth / 16) * 9

      if (containerHeight > entry.contentRect.height) {
        setHeight(entry.contentRect.height)
        setWidth((entry.contentRect.height / 9) * 16)
        return
      }

      setWidth(containerWidth)
      setHeight(containerHeight)
    })

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current)
      }
    }
  }, [])

  return (
    <div
      ref={containerRef}
      className="flex w-full h-full items-center justify-center"
    >
      <div style={{ width, height }}>{children}</div>
    </div>
  )
}

export default Wrapper16x9
